import React, { createContext, useContext, ReactNode, useState, useCallback, useMemo } from 'react';
import { SignupReason } from './SignupCardV2';
import { BaseCardProps, getRecaptchaToken } from '../../shared/shared';
import { SignupApi } from '../../../../api/signupApi';
import { getIsoCode } from '../../../../utils/configurations';
import { ensureExists, ensureUnreachable } from '@signup/shared/src/utils/ensure';
import { SignupFormFields, useSignupFormData } from './SignupFormDataContext';
import {
  countriesKeyedByIsoCode,
  isSuppressionErrorCode,
  SignupApiErrorCode,
  SignupEventType,
  validateCompanyNameWithReason,
  validateInputWithReason,
  validatePhoneNumberWithReason,
} from '@signup/shared';
import { useEmailValidator } from '../../utils/validationUtils';
import { useTelemetry, ValidationResponse } from '@snowflake/core-ui';
import { useDeploymentMetadata } from '../../utils/deploymentUtils';
import { RegionSelect } from '../../../../models';
import {
  isCountryISOCodeInGDPR,
  isCountryISOCodeInUS,
  useIsPhoneNumberRequired,
} from '../../utils/SignupCountryUtils';
import { useIntl } from 'react-intl';
import { SignupEventName } from '../../../../utils/SignupLogger';
import * as configs from '../../../../utils/configurations';
import { SignupParams } from '../../../../utils/ParseQueryParams';
import { useSignupPageContext } from '../../../../pages/SignupContext';
import { getAwsMarketplaceType } from '../../../../utils/signupFlowTypeUtils';

// Define the shape of the context state
export interface SignupFormControllerState {
  submitSignupForm: (params: { formTwoCompletionTime: number }) => Promise<void>;
  accountCreationFinished: boolean;
  regionSelectList: RegionSelect;
  regionSelectListSortedByDistance: boolean;
  validateField: (
    fieldName: SignupFormFields,
    value: string | boolean | null,
  ) => ValidationResponse;
  signupCardProps: BaseCardProps;
  creationErrorCode: SignupApiErrorCode | null;
  clearCreationErrorAndResetRecaptcha: () => void;
}

// Create the context
const SignupFormControllerContext = createContext<SignupFormControllerState | undefined>(undefined);

function useSignupFormInitialization() {
  const { setField } = useSignupFormData();

  // initialize country code for step one.
  React.useEffect(() => {
    // Set country based on IP lookup result
    const ipIsoCode = getIsoCode();
    const matchedIsoCode: string = ipIsoCode && countriesKeyedByIsoCode[ipIsoCode] ? ipIsoCode : '';
    setField('countryIsoCode', matchedIsoCode);
  }, [setField]);
}

function useEmailAgreementStateListener() {
  const { countryIsoCode, setField } = useSignupFormData();
  const { renderingOptions } = useSignupPageContext();

  React.useEffect(() => {
    if (renderingOptions.showEmailAgreementCheckbox) {
      setField('givenEmailOptInChoice', true);
      return;
    }
    if (isCountryISOCodeInUS(countryIsoCode)) {
      setField('givenEmailOptInChoice', false);
    } else if (isCountryISOCodeInGDPR(countryIsoCode)) {
      setField('givenEmailOptInChoice', true);
    } else {
      setField('givenEmailOptInChoice', false);
    }
  }, [countryIsoCode, setField, renderingOptions.showEmailAgreementCheckbox]);
}

// Append a suffix to the school name for student signups, but also limit the length to 39 characters
// This is to make the school names easier to identify in SFDC by human beings, so that a student of
// UC Berkeley could be treated different from a faulty.
// It's not a problem if suffix is not added.
export function decorateSchoolName(schoolName: string): string {
  return `${schoolName} - Student`.slice(0, 39);
}

function useSubmitSignupForm(props: BaseCardProps) {
  const { signupParams, marketingParams, formId, recaptchaRef } = props;
  const awsType = getAwsMarketplaceType();
  const formState = useSignupFormData();
  const [accountCreationFinished, setAccountCreationFinished] = useState(false);
  const [creationErrorCode, setCreationErrorCode] = useState<SignupApiErrorCode | null>(null);
  const { logAction, logEvent } = useTelemetry();
  const isPhoneNumberRequired = useIsPhoneNumberRequired();

  const submitSignupForm = useCallback(
    async ({ formTwoCompletionTime }: { formTwoCompletionTime: number }) => {
      const recaptchaToken = await getRecaptchaToken(recaptchaRef);

      const finalOptOutEmailAgreement =
        isCountryISOCodeInUS(formState.countryIsoCode) ||
        isCountryISOCodeInGDPR(formState.countryIsoCode)
          ? formState.optOutEmailAgreement
          : undefined;

      const options = SignupApi.prepareSignupFields({
        firstName: formState.firstName,
        lastName: formState.lastName,
        email: formState.email,
        countryIsoCode: formState.countryIsoCode,
        company:
          formState.signupReason === SignupReason.Student
            ? decorateSchoolName(formState.schoolName)
            : formState.companyName,
        phoneNumber: isPhoneNumberRequired ? formState.phoneNumber : undefined,
        // Student signup no longer requires a job title. Fill in student in this case.
        role: formState.signupReason === SignupReason.Student ? 'Student' : formState.jobTitle,
        edition: formState.edition,
        cloud: ensureExists(formState.cloud),
        region: formState.region,
        awsType,
        signupParams,
        marketingParams,
        formId,
        recaptchaToken,
        formTwoCompletionTime,
        optInEmailAgreement: formState.optInEmailAgreement,
        optOutEmailAgreement: finalOptOutEmailAgreement,
        givenEmailOptInChoice: formState.givenEmailOptInChoice,
      });

      logAction(SignupEventName.SIGNUP_FORM_TWO_COMPLETE, 'ui_click', 'SignupCard', {
        ...options,
        ...(options.recaptchaToken && { recaptchaToken: 'REDACTED' }),
        ...(options.ampt && { ampt: 'REDACTED' }),
        referrerUrl: configs.getReferrerUrl(),
      });

      const requestStartTime = Date.now() / 1000;

      try {
        const { res, headers } = await SignupApi.createTrial(options);

        if (res.success !== true) {
          setCreationErrorCode(res.errorCode);

          if (isSuppressionErrorCode(res.errorCode)) {
            logEvent({
              event: SignupEventName.SIGNUP_SUPPRESSED,
              type: SignupEventType.UI_RESPONSE_SUCCESS,
              data: {
                thankYouResponseDuration: Date.now() / 1000 - requestStartTime,
                suppressionReason: res.errorCode,
              },
              interaction: false,
            });
          } else {
            logEvent({
              event: SignupEventName.SIGNUP_ACCOUNT_CREATION_ERROR,
              type: SignupEventType.UI_RESPONSE_ERROR,
              data: {
                thankYouResponseDuration: Date.now() / 1000 - requestStartTime,
                txnId: headers.get('X-TXN-ID'),
              },
              interaction: false,
            });
          }
        }

        logEvent({
          event: SignupEventName.SIGNUP_SUCCESS,
          type: SignupEventType.UI_RESPONSE_SUCCESS,
          data: {
            thankYouResponseDuration: Date.now() / 1000 - requestStartTime,
            resultSuccess: res.success,
          },
          interaction: false,
        });
      } catch (err) {
        setCreationErrorCode(SignupApiErrorCode.GENERIC_ERROR);

        logEvent({
          event: SignupEventName.SIGNUP_ACCOUNT_CREATION_ERROR,
          type: SignupEventType.UI_RESPONSE_ERROR,
          data: {
            thankYouResponseDuration: Date.now() / 1000 - requestStartTime,
            txnId: 'null',
          },
          interaction: false,
        });
      } finally {
        setAccountCreationFinished(true);
      }
    },
    [
      formState,
      awsType,
      signupParams,
      marketingParams,
      formId,
      recaptchaRef,
      logAction,
      logEvent,
      isPhoneNumberRequired,
    ],
  );

  const clearCreationErrorAndResetRecaptcha = useCallback(() => {
    setCreationErrorCode(null);
    setAccountCreationFinished(false);
    recaptchaRef.current?.reset();
  }, [setCreationErrorCode, setAccountCreationFinished, recaptchaRef]);

  return {
    submitSignupForm,
    accountCreationFinished,
    creationErrorCode,
    clearCreationErrorAndResetRecaptcha,
  };
}

function useValidateField() {
  const { validateEmailHelper } = useEmailValidator();
  const { signupReason, countryIsoCode } = useSignupFormData();
  const { formatMessage } = useIntl();

  const validateStringInput = useCallback(
    (value: boolean | string | null): string | true => {
      const result = typeof value === 'string' ? validateInputWithReason(value) : 'invalid';
      switch (result) {
        case 'empty':
          return formatMessage({ id: 'This field is required' });
        case 'invalid':
          return formatMessage({ id: 'Enter a different value' });
        case 'invalid_characters':
          return formatMessage({ id: 'Unsupported characters' });
        case 'success':
          return true;
        case 'longer_than_40':
          return formatMessage({ id: 'Enter a value shorter than 40 characters' });
        default:
          ensureUnreachable(result);
      }
    },
    [formatMessage],
  );

  const validateCompanyNameInput = useCallback(
    (value: boolean | string | null): string | true => {
      const result = typeof value === 'string' ? validateCompanyNameWithReason(value) : 'invalid';
      switch (result) {
        case 'empty':
          return formatMessage({ id: 'This field is required' });
        case 'invalid':
          return formatMessage({ id: 'Enter a different value' });
        case 'invalid_characters':
          return formatMessage({ id: 'Unsupported characters' });
        case 'success':
          return true;
        case 'longer_than_40':
          return formatMessage({ id: 'Enter a value shorter than 40 characters' });
        default:
          ensureUnreachable(result);
      }
    },
    [formatMessage],
  );

  const validatePhoneNumberInput = useCallback(
    (value: boolean | string | null): string | true => {
      if (countryIsoCode !== 'JP') {
        return true;
      }

      const result = typeof value === 'string' ? validatePhoneNumberWithReason(value) : 'empty';
      switch (result) {
        case 'empty':
          return formatMessage({ id: 'This field is required' });
        case 'invalid_characters':
          return formatMessage({ id: 'Unsupported characters' });
        case 'success':
          return true;
        case 'longer_than_40':
          return formatMessage({ id: 'Enter a value shorter than 40 characters' });
        default:
          ensureUnreachable(result);
      }
    },
    [formatMessage, countryIsoCode],
  );

  const validateExistence = useCallback(
    (value: boolean | string | null): string | true => {
      return !!value ? true : formatMessage({ id: 'This field is required' });
    },
    [formatMessage],
  );

  return useCallback(
    (fieldName: SignupFormFields, value: boolean | string | null): ValidationResponse => {
      // TODO(GROW-4457): get validation error copies for all scenarios.
      switch (fieldName) {
        // form one
        case 'firstName':
          return validateStringInput(value);
        case 'lastName':
          return validateStringInput(value);
        case 'email':
          return typeof value === 'string' && validateEmailHelper(value);
        case 'countryIsoCode':
          return !!value; // use custom ui for error message.
        case 'signupReason':
          return validateExistence(value);
        case 'optInEmailAgreement':
          return true; // always valid
        case 'optOutEmailAgreement':
          return true; // always valid
        case 'givenEmailOptInChoice':
          return true; // always valid
        case 'phoneNumber':
          return validatePhoneNumberInput(value);

        // form two
        case 'companyName':
          return signupReason === SignupReason.Student || validateCompanyNameInput(value);
        case 'jobTitle':
          return signupReason === SignupReason.Student || validateStringInput(value);
        case 'region':
          return validateExistence(value);
        case 'cloud':
          return !!value; // use custom ui for error message.
        case 'edition':
          return validateExistence(value);
        case 'schoolName':
          // yes, use company name validation for school name
          return signupReason !== SignupReason.Student || validateCompanyNameInput(value);
        case 'termsAgreement':
          return !!value; // use custom ui for error message.
        default:
          ensureUnreachable(fieldName);
      }
    },
    [
      validateEmailHelper,
      signupReason,
      validateStringInput,
      validateCompanyNameInput,
      validateExistence,
      validatePhoneNumberInput,
    ],
  );
}

function useDefaultRegionAutoFill(signupParams: SignupParams, regionSelectList: RegionSelect) {
  const { cloud, region, setField } = useSignupFormData();

  React.useEffect(() => {
    if (!cloud) {
      return;
    }

    // If the region is determined in the url, the logic in this hook does not need to run
    if (signupParams.region || signupParams.regionp) {
      return;
    }

    let suggestedRegion = '';

    const regionList = regionSelectList[cloud];

    if (regionList.some(regionMeatadata => regionMeatadata.value === region)) {
      // current selected region is already on the cloud.
      return;
    }

    // We only suggest a region if we know where the user is (coordinates exist)
    // Picking the first entry for the cloud provider's available regions should be the closest since this list should be sorted.
    // If the cloud provider has no visible regions then the first entry will have a disabled flag set to true, in which case we set a default of ''
    // A cloud should always have an available region, but just in case this value is undefined we set a default of ''
    if (configs.getCoordinates() && regionList?.length && !regionList[0].disabled) {
      suggestedRegion = regionList[0].value ?? '';
    }

    setField('region', suggestedRegion);
  }, [cloud, setField, regionSelectList, signupParams, region]);
}

// Create the provider component
const SignupFormControllerProvider: React.FC<{ children: ReactNode } & BaseCardProps> = props => {
  const { children } = props;
  const { email } = useSignupFormData();

  useSignupFormInitialization();
  useEmailAgreementStateListener();

  const { regionSelectList, regionSelectListSortedByDistance } = useDeploymentMetadata({
    props,
    email,
    filterVisibleRegions: false,
  });

  useDefaultRegionAutoFill(props.signupParams, regionSelectList);

  const {
    submitSignupForm,
    accountCreationFinished,
    creationErrorCode,
    clearCreationErrorAndResetRecaptcha,
  } = useSubmitSignupForm(props);
  const validateField = useValidateField();
  const signupCardProps = useMemo(() => {
    const { children: _, ...extra } = props;
    return extra;
  }, [props]);

  return (
    <SignupFormControllerContext.Provider
      value={{
        submitSignupForm,
        accountCreationFinished,
        validateField,
        regionSelectList,
        signupCardProps,
        regionSelectListSortedByDistance,
        creationErrorCode,
        clearCreationErrorAndResetRecaptcha,
      }}
    >
      {children}
    </SignupFormControllerContext.Provider>
  );
};

// Custom hook for using the context
const useSignupFormController = () => {
  const context = useContext(SignupFormControllerContext);
  if (context === undefined) {
    throw new Error('useSignupFormController must be used within a SignupFormControllerProvider');
  }
  return context;
};

export { SignupFormControllerProvider, useSignupFormController };
